import { useCallback } from 'react';
import {
  Document,
  SpecialProjectByIdDocument,
  SpecialProjectsDocument,
  SpecialProjectsPropsFragment,
  UpdateSpecialProjectInput,
  useUpdateSpecialProjectMutation,
} from 'schema';
import { useCancelRoute } from 'hooks';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useParams } from 'react-router-dom';

type InitialProps = {
  description: string;
  start: Date | undefined;
  title: string;
  images: Array<Document>;
  requiredAmount?: string;
  end?: Date;
  address?: string;
  city?: string;
  categories?: string[];
  feedImage: string;
  logo?: string;
};

type Props = {
  specialProject: SpecialProjectsPropsFragment;
};

const useEditSpecialProjectForm = ({ specialProject }: Props) => {
  const [updateSpecialProject, { loading, data, error }] = useUpdateSpecialProjectMutation();
  const { id } = useParams();

  const existingImages: Array<Document> =
    specialProject?.images.map((item) => {
      return {
        file: {
          id: item.id || '',
          url: item.url || '',
        },
        title: 'Без названия',
      };
    }) || [];

  const initialValues: InitialProps = {
    description: specialProject?.description || '',
    start: specialProject?.start || undefined,
    title: specialProject?.title || '',
    feedImage: specialProject.feedImage.id || '',
    logo: specialProject.logo?.id || '',
    images: existingImages,
    requiredAmount:
      specialProject.account.__typename === 'SpecialProjectAccount'
        ? specialProject?.account?.requiredAmount?.toString()
        : undefined,
    end: specialProject?.end || undefined,
    address: specialProject?.address?.fullAddress || undefined,
    city: specialProject?.city || undefined,
    categories: specialProject?.categories.map(({ id }) => id) || undefined,
  };

  const onSubmit = useCallback(
    async (values: InitialProps) => {
      const input: UpdateSpecialProjectInput = {
        id,
        ...values,
        categories: values.categories as string[],
        requiredAmount: values.requiredAmount ? parseInt(values.requiredAmount) : null,
        images: values.images.map((image) => image.file.id),
      };
      try {
        return await updateSpecialProject({
          variables: { input },
          refetchQueries: [
            {
              query: SpecialProjectByIdDocument,
              variables: { id },
            },
            {
              query: SpecialProjectsDocument,
              variables: { first: 50, includeClosedOrCancelled: false },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления спецпроекта');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [id, updateSpecialProject, error]
  );

  const { onCancel } = useCancelRoute(`/special-projects/manage/${id}`);

  return {
    initialValues,
    onSubmit,
    onCancel,
    loading,
    data,
    error,
  };
};

export default useEditSpecialProjectForm;

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import { useStyles } from '../../BenefactorManagePage/components/BenefactorCard/useStyles';
import { Order } from '../../../../schema';

type Props = {
  payment: Order;
};

export const ActualAmountSummary = ({ payment }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
        Фактическое распределение суммы
      </Typography>
      <Box className={classes.accounts} textAlign="start">
        <PropertiesTable>
          {payment?.actualAmountSummary?.oneTimeProjectDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на проект"
              value={payment?.actualAmountSummary?.oneTimeProjectDonation}
            />
          ) : null}
          {payment?.actualAmountSummary?.oneTimeWardDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на подопечных"
              value={payment?.actualAmountSummary?.oneTimeWardDonation}
            />
          ) : null}
          {payment?.actualAmountSummary?.projectSubscription ? (
            <PropertiesTableRow
              caption="Подписка на проект"
              value={payment?.actualAmountSummary?.projectSubscription}
            />
          ) : null}
          {payment?.actualAmountSummary?.projectSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на проект"
              value={payment?.actualAmountSummary?.projectSubscriptionCharge}
            />
          ) : null}
          {payment?.actualAmountSummary?.specialProjectDonation ? (
            <PropertiesTableRow
              caption="Пожертвование на спецпроект"
              value={payment?.actualAmountSummary?.specialProjectDonation}
            />
          ) : null}
          {payment?.actualAmountSummary?.wardSubscription ? (
            <PropertiesTableRow
              caption="Подписка на подопечных"
              value={payment?.actualAmountSummary?.wardSubscription}
            />
          ) : null}
          {payment?.actualAmountSummary?.wardSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на подопечных"
              value={payment?.actualAmountSummary?.wardSubscriptionCharge}
            />
          ) : null}
        </PropertiesTable>
      </Box>
    </>
  );
};

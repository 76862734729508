import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Theme,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import useAddSpecialProjectForm from './useAddSpecialProjectForm';
import {
  TextField,
  AutoFocusedForm,
  required,
  SelectField,
  AddressField,
  DateTimeField,
  FilesUploadField,
  PhotoField,
} from 'components';
import { useCitiesQuery, Document, useCategoriesQuery } from 'schema';
import { MutableButton } from 'ui/MutableButton';
import { toast } from 'react-toastify';
import { RedButton } from 'components/Buttons';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  primary: {
    marginBottom: '20px',
  },
  secondary: {
    marginBottom: '20px',
  },
  header: {
    fontWeight: 'bolder',
    marginBottom: '10px',
    marginTop: '20px',
  },
  subheader: {
    color: theme.palette.warning.main,
  },
  privateHeader: {
    fontWeight: 'bolder',
    marginBottom: '20px',
  },
  accessButton: {
    marginLeft: '15px',
    marginRight: '15px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      color: theme.palette.success.dark,
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const AddSpecialProjectForm = () => {
  const classes = useStyles();
  const { initialValues, onSubmit, data, onCancel, loading, error } = useAddSpecialProjectForm();
  const { data: citiesData } = useCitiesQuery();
  const { data: categoriesData } = useCategoriesQuery();
  const cities = citiesData?.cities?.nodes;
  const categories = categoriesData?.categories
    ?.slice()
    .map((category) => category)
    .sort((a, b) => {
      return a.order - b.order;
    });

  if (error) {
    toast.error('Ошибка создания спецпроекта');
  }

  if (data) {
    toast.success('Спецпроект создан');
    onCancel();
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.primary}>
              <CardHeader
                title="Основные сведения"
                subheaderTypographyProps={{ className: classes.subheader }}
              />
              <Divider />
              <CardContent>
                <Typography variant="h5" component="h5" className={classes.header}>
                  Изображение для ленты
                </Typography>
                <Grid container spacing={4}>
                  <Grid item lg={4} md={4} xl={3} xs={12}>
                    <Field name="feedImage" component={PhotoField} />
                    <Typography variant="h5" component="h5" className={classes.header}>
                      Изображение логотипа
                    </Typography>
                    <Field name="logo" component={PhotoField} />
                  </Grid>

                  <Grid item lg={8} md={8} xl={9} xs={12}>
                    <Typography variant="h5" component="h5" className={classes.privateHeader}>
                      Общая информация
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={6} md={6} xl={6} xs={6}>
                        <Field
                          name="title"
                          label="Название"
                          component={TextField}
                          validate={required}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={6}>
                        <Field
                          name="categories"
                          label={'Категория *'}
                          render={({ input }) => {
                            const { name, onChange, value } = input;
                            const selectedNames = categories
                              ?.filter((category) => value.includes(category.id))
                              .map((category) => category.name);
                            return (
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel id={'label'}>Категория *</InputLabel>
                                <Select
                                  name={name}
                                  labelId={'label'}
                                  value={value}
                                  multiple
                                  input={<OutlinedInput label="Категория *" />}
                                  renderValue={() => selectedNames?.join(', ')}
                                  onChange={onChange}
                                >
                                  {categories?.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>
                                      <Checkbox checked={value.includes(id)} />
                                      <ListItemText primary={name} />{' '}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <Field
                          name="address"
                          label="Адрес"
                          component={AddressField}
                          needDetermineCoordinates="hasCoordinates"
                        />
                      </Grid>

                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Field name="city" label="Город" component={SelectField}>
                          {cities?.map(({ name, id }) => (
                            <MenuItem key={id} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Field
                          name="description"
                          label="Описание"
                          component={TextField}
                          validate={required}
                          multiline
                          rows={7}
                          rowsMax={50}
                        />
                      </Grid>
                    </Grid>

                    <Typography variant="h5" component="h5" className={classes.header}>
                      Сроки и сумма
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={4} md={6} xl={6} xs={12}>
                        <Field
                          name="start"
                          label="Дата начала сбора средств *"
                          fullWidth
                          component={DateTimeField}
                          validate={required}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xl={6} xs={12}>
                        <Field
                          name="end"
                          fullWidth
                          label="Дата окончания сбора средств"
                          component={DateTimeField}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xl={6} xs={12}>
                        <Field
                          name="requiredAmount"
                          label="Требуемая сумма"
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h5" component="h5" className={classes.header}>
                      Изображения
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <FieldArray<Document>
                          name="images"
                          component={FilesUploadField}
                          accept={['image/jpeg', 'image/jpg', 'image/png']}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <MutableButton
              className={classes.accessButton}
              loading={loading}
              color="primary"
              disabled={pristine}
              size="medium"
              type="submit"
              variant="contained"
            >
              Сохранить
            </MutableButton>
            <RedButton
              color="primary"
              size="medium"
              type="button"
              variant="contained"
              onClick={onCancel}
            >
              Отменить
            </RedButton>
          </form>
        );
      }}
    />
  );
};

export default AddSpecialProjectForm;

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PropertiesTable, PropertiesTableRow } from '../../../../ui/PropertiesTable';
import { useStyles } from '../../BenefactorManagePage/components/BenefactorCard/useStyles';
import { Order } from '../../../../schema';

type Props = {
  payment: Order;
};

export const IntentAmountSummary = ({ payment }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="div" variant="h5" align="center" className={classes.headerLabel}>
        Запрошенное распределение суммы
      </Typography>
      <Box className={classes.accounts} textAlign="start">
        <PropertiesTable>
          {payment?.intentAmountSummary?.oneTimeProjectDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на проект"
              value={payment?.intentAmountSummary?.oneTimeProjectDonation}
            />
          ) : null}
          {payment?.intentAmountSummary?.oneTimeWardDonation ? (
            <PropertiesTableRow
              caption="Разовое пожертвование на подопечных"
              value={payment?.intentAmountSummary?.oneTimeWardDonation}
            />
          ) : null}
          {payment?.intentAmountSummary?.projectSubscription ? (
            <PropertiesTableRow
              caption="Подписка на проект"
              value={payment?.intentAmountSummary?.projectSubscription}
            />
          ) : null}
          {payment?.intentAmountSummary?.projectSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на проект"
              value={payment?.intentAmountSummary?.projectSubscriptionCharge}
            />
          ) : null}
          {payment?.intentAmountSummary?.specialProjectDonation ? (
            <PropertiesTableRow
              caption="Пожертвование на спецпроект"
              value={payment?.intentAmountSummary?.specialProjectDonation}
            />
          ) : null}
          {payment?.intentAmountSummary?.wardSubscription ? (
            <PropertiesTableRow
              caption="Подписка на подопечных"
              value={payment?.intentAmountSummary?.wardSubscription}
            />
          ) : null}
          {payment?.intentAmountSummary?.wardSubscriptionCharge ? (
            <PropertiesTableRow
              caption="Списание подписки на подопечных"
              value={payment?.intentAmountSummary?.wardSubscriptionCharge}
            />
          ) : null}
        </PropertiesTable>
      </Box>
    </>
  );
};

import { DonationsPaymentQuery, Order, OrderStatus } from '../serverTypes';

export const getOrderStatus = (order: Order) => {
  const { status } = order;
  switch (status) {
    case OrderStatus.Completed:
      return 'Обработка заказа завершена';
    case OrderStatus.Created:
      return 'Заказ создан';
    case OrderStatus.Expired:
      return 'Заказ устарел';
    case OrderStatus.PaymentChallenge:
      return 'Необходимо вмешательство пользователя для продолжения оплаты (например, ввести 3D Secure код)';
    case OrderStatus.PaymentFailed:
      return 'Платёж не удался';
    case OrderStatus.PaymentInProgress:
      return 'Ожидается оплата заказа';
    case OrderStatus.PaymentSucceeded:
      return 'Платёж успешно завершён';
  }
};

export const getTypeDonation = (order: DonationsPaymentQuery | undefined) => {
  const donationIntent = order?.orderById?.donationIntents;
  switch (donationIntent?.map(({ __typename }) => __typename)[0]) {
    case 'SubscriptionChargeIntent':
      return 'Оплата ежемесячной подписки';
    case 'WardsSubscriptionIntent':
      return 'Подписка на регулярные потребности указанных подопечных';
    case 'WardsReserveDonationIntent':
      return 'Пожертвование в резерв подопечных';
    case 'WardRegularNeedsDonationIntent':
      return 'Пожертвование на регулярные потребности подопечного';
    case 'WardOneTimeNeedDonationIntent':
      return 'Пожертвование на разовую потребность подопечного';
    case 'SpecialProjectDonationIntent':
      return 'Пожертвование на спецпроект';
    case 'ProjectSubscriptionIntent':
      return 'Подписка на развитие проекта';
    case 'ProjectDonationIntent':
      return 'Пожертвование на развитие проекта';
    case 'LegacySubscriptionIntent':
      return 'Подписка';
    case 'LegacySubscriptionChargeIntent':
      return 'Оплата ежемесячной подписки';
    case 'LegacyOneTimeDonationIntent':
      return 'Разовое пожертвование';
    case 'BenefactorDepositAccountDonationIntent':
      return 'Зачисление средства на счёт-депозит жертвователя';
  }
};

import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  NewOrderInformation,
  ProjectSubscriptionIntent,
  ProjectSubscriptionIntentInput,
  SubscriptionIntentUnion,
  useNewOrderInformationQuery,
  WardsSubscriptionIntent,
  WardsSubscriptionIntentInput,
} from '../../../../../../../../schema';
import { useStyles } from '../useStyles';
import { RedButton } from '../../../../../../../../components/Buttons';
import { toast } from 'react-toastify';
import { Loading } from '../../../../../../../../ui/Loading';

type Props = {
  subscriptions: SubscriptionIntentUnion[];
  setActiveStep: (updateFunction: (prevStep: number) => number) => void;
  handleClose: () => void;
  setNewOrderInformation: (newOrderInformation: NewOrderInformation) => void;
  // setNewProjectOrderInformation: (newOrderInformation: NewOrderInformation) => void;
  benefactorId: string;
  wardsSubscriptionIntentInput: WardsSubscriptionIntentInput;
  projectSubscriptionIntentInput: ProjectSubscriptionIntentInput;
  setWardsSubscriptionIntentInput: (subscription: WardsSubscriptionIntentInput) => void;
  setProjectSubscriptionIntentInput: (subscription: ProjectSubscriptionIntentInput) => void;
};

export const ConfirmNewSubscriptionsStep = ({
  subscriptions,
  setActiveStep,
  handleClose,
  setNewOrderInformation,
  // setNewProjectOrderInformation,
  benefactorId,
  wardsSubscriptionIntentInput,
  projectSubscriptionIntentInput,
  setWardsSubscriptionIntentInput,
  setProjectSubscriptionIntentInput,
}: Props) => {
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<any[] | undefined>([]);
  const [wardsSubscriptions, setWardsSubscriptions] = useState<WardsSubscriptionIntent>();
  const [projectSubscriptions, setProjectSubscription] = useState<ProjectSubscriptionIntent>();
  const PROJECT_ID = 'project';

  const {
    data: newOrderData,
    error: newOrderError,
    loading: newOrderLoading,
    refetch: refetchNewOrderData,
  } = useNewOrderInformationQuery({
    variables: {
      donationIntents: {
        wardsSubscription: wardsSubscriptionIntentInput,
      },
      benefactorId,
    },
  });

  const {
    error: newOrderProjectError,
    loading: newOrderProjectLoading,
    refetch: refetchNewProjectOrderData,
  } = useNewOrderInformationQuery({
    variables: {
      donationIntents: {
        projectSubscription: projectSubscriptionIntentInput,
      },
      benefactorId,
    },
  });

  const styles = useStyles();

  const getType = (subscriptions: SubscriptionIntentUnion[]) => {
    // eslint-disable-next-line array-callback-return
    return subscriptions.map((item) => {
      switch (item.__typename) {
        case 'WardsSubscriptionIntent':
          return setWardsSubscriptions(item);
        case 'ProjectSubscriptionIntent':
          return setProjectSubscription(item);
      }
    });
  };

  useEffect(() => {
    getType(subscriptions);
  }, [subscriptions]);

  useEffect(() => {
    setSelectedSubscriptions(() => {
      const selectedIds =
        wardsSubscriptions?.wardsSubscriptionsAmounts.map((item) => item.ward.id) || [];
      if (projectSubscriptions) {
        selectedIds.push(PROJECT_ID);
      }
      return selectedIds;
    });

    if (wardsSubscriptions) {
      setWardsSubscriptionIntentInput({
        commissionPercentage: wardsSubscriptions?.commissionPercentage,
        isCommissionCoveredByBenefactor: wardsSubscriptions?.isCommissionCoveredByBenefactor,
        wardsSubscriptionsAmounts: wardsSubscriptions?.wardsSubscriptionsAmounts.map((item) => ({
          amount: item.amount,
          wardId: item.ward.id,
        })),
      });
    }

    if (projectSubscriptions) {
      setProjectSubscriptionIntentInput({
        amount: projectSubscriptions.amount,
      });
    }
  }, [
    projectSubscriptions,
    setProjectSubscriptionIntentInput,
    setWardsSubscriptionIntentInput,
    wardsSubscriptions,
  ]);

  useEffect(() => {
    if (wardsSubscriptions && selectedSubscriptions) {
      const selectedWardsSubscriptionsAmounts = wardsSubscriptions.wardsSubscriptionsAmounts.filter(
        (item) => selectedSubscriptions.includes(item.ward.id)
      );
      setWardsSubscriptionIntentInput({
        commissionPercentage: wardsSubscriptions.commissionPercentage,
        isCommissionCoveredByBenefactor: wardsSubscriptions.isCommissionCoveredByBenefactor,
        wardsSubscriptionsAmounts: selectedWardsSubscriptionsAmounts.map((item) => ({
          amount: item.amount,
          wardId: item.ward.id,
        })),
      });
    }

    if (projectSubscriptions && selectedSubscriptions?.includes(PROJECT_ID)) {
      setProjectSubscriptionIntentInput({
        amount: projectSubscriptions.amount,
      });
    } else {
      setProjectSubscriptionIntentInput({
        amount: 0,
      });
    }
  }, [
    projectSubscriptions,
    selectedSubscriptions,
    setProjectSubscriptionIntentInput,
    setWardsSubscriptionIntentInput,
    wardsSubscriptions,
  ]);

  useEffect(() => {
    // Рефетч даты
    refetchNewOrderData({
      donationIntents: { wardsSubscription: wardsSubscriptionIntentInput },
      benefactorId,
    });

    refetchNewProjectOrderData({
      donationIntents: { projectSubscription: projectSubscriptionIntentInput },
      benefactorId,
    });
  }, [
    wardsSubscriptionIntentInput,
    projectSubscriptionIntentInput,
    benefactorId,
    refetchNewOrderData,
    refetchNewProjectOrderData,
  ]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setSelectedSubscriptions((prevSelected) => {
        if (!prevSelected) {
          return [id];
        } else if (!prevSelected.includes(id)) {
          return [...prevSelected, id];
        }
        return prevSelected;
      });
    } else {
      setSelectedSubscriptions((prevSelected) =>
        prevSelected?.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const totalAmount =
    (wardsSubscriptions?.wardsSubscriptionsAmounts.reduce((total, subscription) => {
      if (selectedSubscriptions?.includes(subscription.ward.id)) {
        return total + subscription.amount;
      }
      return total;
    }, 0) || 0) +
    (selectedSubscriptions?.includes(PROJECT_ID) ? projectSubscriptions?.amount || 0 : 0);

  const handleNext = () => {
    if (newOrderError || newOrderProjectError) {
      toast.error(newOrderError?.message || newOrderProjectError?.message);
    }
    setNewOrderInformation(newOrderData?.newOrderInformation as NewOrderInformation);
    // setNewProjectOrderInformation(newOrderProjectData?.newOrderInformation as NewOrderInformation);
    // setAmount(totalAmount!);
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  if (newOrderLoading || newOrderProjectLoading) {
    return <Loading />;
  }

  return (
    <>
      {wardsSubscriptions && selectedSubscriptions && (
        <>
          <DialogContent className={styles.dialogContentContainer}>
            <TableContainer className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          projectSubscriptions
                            ? selectedSubscriptions.length ===
                              wardsSubscriptions.wardsSubscriptionsAmounts.length + 1
                            : selectedSubscriptions.length ===
                              wardsSubscriptions.wardsSubscriptionsAmounts.length
                        }
                        indeterminate={
                          selectedSubscriptions &&
                          wardsSubscriptions &&
                          selectedSubscriptions.length > 0 &&
                          selectedSubscriptions.length <
                            wardsSubscriptions.wardsSubscriptionsAmounts.length
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedSubscriptions(() => {
                              const selectedIds = wardsSubscriptions?.wardsSubscriptionsAmounts.map(
                                (item) => item.ward.id
                              );
                              if (projectSubscriptions) {
                                selectedIds.push(PROJECT_ID);
                              }
                              return selectedIds;
                            });
                          } else {
                            setSelectedSubscriptions([]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {!projectSubscriptions ? 'Подопечный' : 'Подопечный/Проект'}{' '}
                    </TableCell>
                    <TableCell align="right">Сумма</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectSubscriptions ? (
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedSubscriptions?.includes(PROJECT_ID)}
                          onChange={(event) => handleCheckboxChange(event, PROJECT_ID)}
                        />
                      </TableCell>
                      <TableCell>На развитие проекта</TableCell>
                      <TableCell align="right">{projectSubscriptions?.amount}₽</TableCell>
                    </TableRow>
                  ) : null}
                  {wardsSubscriptions?.wardsSubscriptionsAmounts.map((subscription) => (
                    <TableRow key={subscription.ward.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedSubscriptions?.includes(subscription.ward.id)}
                          onChange={(event) => handleCheckboxChange(event, subscription.ward.id)}
                        />
                      </TableCell>
                      <TableCell>{subscription?.ward?.publicInformation?.name.fullName}</TableCell>
                      <TableCell align="right">{subscription.amount}₽</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" align="right" className={styles.totalAmount}>
              Общая сумма: {totalAmount}₽
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={selectedSubscriptions.length === 0}
            >
              Далее
            </Button>
            <RedButton onClick={handleClose}>Отменить</RedButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

import React, { useMemo } from 'react';
import {
  WardDonationSubscription,
  useBenefactorSubscriptionsQuery,
  BenefactorExtendedPropsFragment,
} from 'schema/serverTypes';
import { useQueryVariables, ColumnConfig, DataConnection } from 'hooks';
import { AmountLabel, DataGrid } from 'components';
import { DataGridProps } from 'components/DataGrid/types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AccessibilityNew, AccountBalance } from '@material-ui/icons';
import { useStyles } from '../useStyles';

const getDataKey = (subscription: WardDonationSubscription) => subscription.id;

const getDate = (date: string) => {
  return moment(date as string).format('D MMMM YYYY');
};

interface BenefactorSubscriptionsProps {
  benefactor: BenefactorExtendedPropsFragment;
}

export const BenefactorSubscriptions = (props: BenefactorSubscriptionsProps) => {
  const classes = useStyles();

  const {
    benefactor: { id },
  } = props;

  const config = useMemo(() => {
    const columns: ColumnConfig<WardDonationSubscription>[] = [
      {
        id: 'type',
        header: 'Тип',
        style: { width: '5%' },
        render: (subscription: WardDonationSubscription) =>
          subscription?.__typename === 'WardDonationSubscription' ? (
            <AccessibilityNew className={classes.iconColor} />
          ) : (
            <AccountBalance className={classes.iconColor} />
          ),
      },
      {
        id: 'amount',
        header: 'Сумма',
        style: { width: '15%' },
        render: (subscription: WardDonationSubscription) => (
          <AmountLabel
            value={subscription.amount}
            commissionAmount={subscription.commissionAmount}
          />
        ),
      },
      {
        id: 'date',
        header: 'Дата подписки',
        style: { width: '20%' },
        render: (subscription: WardDonationSubscription) => getDate(subscription.createdAt),
      },
      {
        id: 'name',
        header: 'Подопечный / Проект',
        render: (subscription: WardDonationSubscription) =>
          subscription?.__typename === 'WardDonationSubscription' ? (
            <Link className={classes.link} to={`/wards/manage/${subscription.ward.id}`}>
              {subscription.ward.publicInformation?.name.fullName}
            </Link>
          ) : (
            <div>Проект</div>
          ),
      },
    ];
    return {
      columns,
    };
  }, [classes]);

  const { onPage, variables, extractPagedData, columns } = useQueryVariables<
    WardDonationSubscription
  >(config);

  const { data } = useBenefactorSubscriptionsQuery({
    variables: { id, ...variables },
  });

  const entities = data?.benefactorById?.subscriptions as DataConnection<WardDonationSubscription>;
  const {
    data: subscriptions,
    count,
    startCursor,
    endCursor,
    page,
    rowsPerPage,
  } = extractPagedData(entities);

  const gridProps: DataGridProps<WardDonationSubscription> = {
    data: subscriptions,
    onPage,
    columns,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
    onSort: () => {},
    onSearch: () => {},
  };

  return <DataGrid<WardDonationSubscription> {...gridProps} />;
};
